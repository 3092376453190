import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import {
    GET_USER_SUCCESS,
    GET_USER_FAILED,
    USER_COMMON_ERROR,
    GET_SINGLE_USER_SUCCESS,
    GET_SINGLE_USER_FAILED,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILED,
    USER_CREATED_POSTS_SUCCESS,
    USER_CREATED_POSTS_FAILED,
    USERS_LIST_SUCCESS,
    USERS_LIST_FAILED,
    STEAMER_LIST_SUCCESS,
    STEAMER_LIST_FAILED,
    SEARCH_USER , 
    SEARCH_USER_FAILED , 
    UPDATE_HEADER_SUCCESS,
    UPDATE_HEADER_FAILED,
    MUTE_CREATER_SUCCESS,
    MUTE_CREATER_FAILED,
    UNMUTE_CREATER_SUCCESS,
    UNMUTE_CREATER_FAILED,
    CLEAR_CREATER_DATA
  } from "./types";
import { errorActionCreator } from './error.action';

  const config = {
    headers: {
      "Content-Type": "multipart/form-data; charset=utf-8;"
      
    }
  }



function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
      dispatch({
        type: USER_COMMON_ERROR,
        payload: data.error.message,
      });
    } else if (data.status === 401) {   
      auth.logout()
    } else {
      dispatch({
        type: USER_COMMON_ERROR,
        payload: data.message,
      });
    }
}

/** using for get user details  */
export const getUserProfile = (id) => {
    return async (dispatch) => {
        try{
            const response = await API.apiGetUrl('user', '/user-detail') ;
            if (response.data && response.data.success) {
                await dispatch({ type: GET_USER_SUCCESS, payload:response.data});
            } else {
                await dispatch({ type: GET_USER_FAILED, payload: response.data.message});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}

export const getSingleUser = (id)=>{
    return async (dispatch) => {
      try{
          const response = await API.apiGetUrl('user', '/single-user' , `/${id}`) ;
          if (response.data && response.data.success) {
              await dispatch({ type: GET_SINGLE_USER_SUCCESS, payload:response.data});
          } else {
              await dispatch({ type: GET_SINGLE_USER_FAILED, payload: response.data.message});
          }
      }catch(error){
          errorRequest(error, dispatch);
      }
    }
}


export const updateUserProfile = (payload) =>{
    return async (dispatch) => {
      try{
          const response = await API.apiUpdateWithFileUplaod('user', '/edit/user-detail' , payload ) ;
          if(response.data && response.data.success){
              await dispatch({ type: UPDATE_USER_SUCCESS, payload: response.data});
          }else{
              await dispatch({ type: UPDATE_USER_FAILED, payload: response.data});
          }
      }catch(error){
          errorRequest(error, dispatch);
      }
    }
}


export const updateHeader = () =>{
  return async (dispatch) => {
    try{
        const response = await API.apiUpdateWithFileUplaod('user', '/set_header' , '') ;
        if(response.data && response.data.success){
            await dispatch({ type: UPDATE_HEADER_SUCCESS, payload: response.data});
        }else{
            await dispatch({ type: UPDATE_HEADER_FAILED, payload: response.data});
        }
    }catch(error){
        errorRequest(error, dispatch);
    }
  }
}


export const getProfilePosts = (payload) =>{
  return async (dispatch) => {
    try{
        const response = await API.apiGetUrl('post', '/profile-posts' , payload ) ;
        if(response.data && response.data.success){
            await dispatch({ type: USER_CREATED_POSTS_SUCCESS, payload: response.data});
        }else{
            await dispatch({ type: USER_CREATED_POSTS_FAILED, payload: response.data});
        }
    }catch(error){
        errorRequest(error, dispatch);
    } 
  }
}

export const getUsersList = () => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet('user', '/get-users');
      if(response.data && response.data.success){
        await dispatch({ type: USERS_LIST_SUCCESS, payload: response.data  });
      }else{
        await dispatch({ type: USERS_LIST_FAILED, payload: response.data });
      }
    } catch (error) {
      errorActionCreator(USERS_LIST_FAILED, error, dispatch)
    }
   
  }
}

export const searchUser = (payload) =>{
  return async (dispatch) => {
    try {
      const response = await API.apiGetUrl('user', '/search-user' , payload) ;
      if(response.data && response.data.success){
        await dispatch({ type: SEARCH_USER, payload: response.data  });
      }else{
        await dispatch({ type: SEARCH_USER_FAILED, payload: response.data });
      }
    } catch (error) {
      errorActionCreator(USERS_LIST_FAILED, error, dispatch)
    }
   
  }
}

export const getTopStreamer = () => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet('user', '/top-steamer');
      if(response.data && response.data.success){
        await dispatch({ type: STEAMER_LIST_SUCCESS, payload: response.data  });
      }else{
        await dispatch({ type: STEAMER_LIST_FAILED, payload: response.data });
      }
    } catch (error) {
      errorActionCreator(STEAMER_LIST_FAILED, error, dispatch)
    }
   
  }
}

export const muteCreater = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('user', '/mute-creater', payload);
      if(response.data && response.data.success){
        await dispatch({ type: MUTE_CREATER_SUCCESS, payload: response.data  });
      }else{
        await dispatch({ type: MUTE_CREATER_FAILED, payload: response.data });
      }
    } catch (error) {
      errorActionCreator(MUTE_CREATER_FAILED, error, dispatch)
    }
   
  }
}


export const unmuteCreater = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiDelete('user', '/unmute-creater', payload);
      if(response.data && response.data.success){
        await dispatch({ type: UNMUTE_CREATER_SUCCESS, payload: response.data  });
      }else{
        await dispatch({ type: UNMUTE_CREATER_FAILED, payload: response.data });
      }
    } catch (error) {
      errorActionCreator(STEAMER_LIST_FAILED, error, dispatch)
    }
   
  }
}

export const clearUnmuteState = () =>{
  return async(dispatch)=>{
    try{
      await dispatch({ type: CLEAR_CREATER_DATA, payload: 'clear'});

    }catch(error){
      errorActionCreator(STEAMER_LIST_FAILED, error, dispatch)
    }

  }
}


