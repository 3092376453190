import React, { useState , useEffect} from 'react';
import { useSelector, useDispatch} from "react-redux";
import { Link , useNavigate} from 'react-router-dom';
import { Navbar, Row, Col, Form, Button, Container, ListGroup, Dropdown, NavDropdown ,spinner} from 'react-bootstrap';
import { BiSearchAlt2, BiCommentDots, BiBell, BiChevronDown } from 'react-icons/bi';
import { BsMoon } from 'react-icons/bs';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { TiShoppingCart, TiTimes } from 'react-icons/ti';
import { WiDaySunny } from 'react-icons/wi';
import { decodedLoggedUser , logout , getUserNotifications , removeNotification} from '../../helpers/auth';
import { getUserProfile , updateHeader,clearUnmuteState} from '../../redux/actions/user.action';
import { getCartItems } from '../../redux/actions/shoppingCart.action';
import { getSearchData } from '../../redux/actions/search.action';
import { getNotification } from '../../redux/actions/post.action';
import moment from 'moment';
import Autosuggest from 'react-autosuggest';
import 'bootstrap/dist/css/bootstrap.min.css';
import './headerStyle.css';
import '../../App.css';

function PageHeader() {
    const [isActive, setIsActive] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loader , setLoader] = useState(true)
    const [search , setSearch] = useState('');
    const [suggestion , setSuggestion] = useState([]);
    const [userInfo , setUserInfo] = useState(false) ;
    const [cartItems , setCartItems] = useState('')
    const [userProfile , setUserProfile] = useState('') ; 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const userResult = useSelector(state => state.user);
    const cartResult = useSelector(state =>state.cart);
    const postResult = useSelector(state=>state.post);
    const messengerResult = useSelector(state=>state.messenger)
    const GlobalSearchData = useSelector(state =>state.search);
    const showHeader = useSelector(state =>state.showHeader);
    const [notification , setNotification] = useState('');
    const [notificationCount , setNotificationCount] = useState('')
    const [unseenCount , setUnseenCount] = useState(0);

    useEffect(() => {
        clearMuteState()
        if (auth && auth.loggedIn) {
            setIsLoggedIn(true) 
            let token = decodedLoggedUser()
            setUserInfo(token)
            dispatch(getUserProfile(token.aud))
            dispatch(getCartItems())
            const data ={userId:token.aud}
            getUserNotifications(data)
            dispatch(getNotification(data))
            setLoader(false);
        }else{
            setLoader(false);
        }
    },[]);


    const clearMuteState = () =>{
        dispatch(clearUnmuteState())
    }

    const handleClick = event => {
        setIsActive(current => !current);
        if(auth.loggedIn){
            dispatch(updateHeader())
        }
    };


    useEffect(()=>{
        if(userResult.getUserDetails && userResult.getUserDetails.success){
            if(userResult.getUserDetails.profile){
                setUserProfile(userResult.getUserDetails.profile)
            }
        }
        if(cartResult && cartResult.carItemList){
            setCartItems( cartResult.carItemList.itemList )
        }
        if(postResult && postResult.notificationList){
            if(postResult.notificationList && postResult.notificationList.notificationList){
                setNotification(postResult.notificationList.notificationList)
                setNotificationCount(postResult.notificationList.count)
            }
        }
    },[userResult , cartResult , postResult , showHeader])


    const handleSearchData = (data) =>{
        data.userId =  userProfile.id

        console.log("data" , data);


        dispatch(getSearchData(data))
        navigate('/search', { state: data})
    }

    const handleRemoveNotication = (item) =>{
        const data = {id:item.id}
        const dataTwo ={userId:userProfile.id}
        removeNotification(data)
        dispatch(getNotification(dataTwo))
    }

    const consolidateCommentLikes = (notifications) => {
        if(notifications && notifications.length > 0){
            const consolidated = [];
            notifications.forEach((item,idx) => {
                if (item.type === "COMMENTLIKE" && item.comment && item.comment.Comment_Likes && item.Post) {
                    item.comment.Comment_Likes.forEach((like) => {
                        consolidated.push({ likes:like, Post: item.Post, id: item.id, like_id: like.id });
                    });
                }
            });
        
            if(consolidated && consolidated.length > 0){
                const uniqueConsolidated = consolidated.filter((v, i, a) => a.findIndex(t => t.like_id === v.like_id) === i);
                return uniqueConsolidated;
            }
        }
    };


    const renderNonCommentLikesNotifications = (notifications) => {
        if(notifications && notifications.length > 0){
            return notifications.map((item, idx) => {
                const { Post } = item;
                const{Like} = item ; 
                const {comment} = item ;
                let url = '#';
                let message = '';
                let messagePost ='';
                let userDetail ='';
        
                if (Post) {
                    if (Post.PostCategory.slug === 'audio') {
                        if (Post.Postmeta.length) {
                            url = `/audio/album/${Post.slug}`;
                        }
                        else {
                            url = `/audio/single/${Post.slug}`;
                        }
                    }
                    else if (Post.PostCategory.slug === 'video' || Post.PostCategory.slug === 'film') {
                        url = `/video/${Post.slug}`;
                    }
                    else {
                        url = `/${Post.PostCategory.slug}/${Post.slug}`
                    }
                    if (item.type === 'LIKE' && Like !== null) {
                        message = "your post"
                        messagePost ="liked"
                        userDetail = Like.user || ''
                    }
                    if(comment !== null){
                        message = "your post"
                        messagePost ="commented on "
                        userDetail = comment.user || ''
                    }
                } 
                if (!userDetail) {
                    return []; // Skip rendering if userDetail is undefined
                }
                return (
                    <ul className='notification'>
                            <li key={idx}>
                                <span className='noti--image'>
                                    <Link to={`/${userDetail.username}`}>
                                        <img src={userDetail.image || '/images/user.png' } alt='Profile image' />
                                    </Link>
                                </span>
                                <p>
                                    <span className='notification-wrap'><Link to={`/${userDetail.username}`}>{userDetail.first} {userDetail.last} </Link> &nbsp;  <Link to={url}> {messagePost}  {message}</Link>
                                    </span><span>{moment(item.createdAt).fromNow()}</span>
                                </p>
                                <TiTimes onClick = {()=>handleRemoveNotication(item)}/>
                            </li>
                    </ul>
                )
            });
        }else{
            return []
        }
    };

    const renderCommentLikesNotifications = (notifications) => {
        if(notifications && notifications.length > 0){
            return notifications.map((item, idx) => {
                const { Post } = item;
                const{likes} = item ; 
                let url = '#';
                let message = '';
                let userDetail =likes.user;
                let messagePost = "liked your comment";
            
                if (Post) {
                    if (Post.PostCategory.slug === 'audio') {
                        if (Post.Postmeta.length) {
                            url = `/audio/album/${Post.slug}`;
                        } else {
                            url = `/audio/single/${Post.slug}`;
                        }
                    } else if (Post.PostCategory.slug === 'video' || Post.PostCategory.slug === 'film') {
                        url = `/video/${Post.slug}`;
                    } else {
                        url = `/${Post.PostCategory.slug}/${Post.slug}`;
                    }
                }

                if (!userDetail) {
                    return []; // Skip rendering if userDetail is undefined
                }
        
                return (
                    <ul className='notification'>
                        {/* <Link to={url} onClick={()=>handleRemoveNotication(item)}> */}
                            <li key={idx}>
                                <span className='noti--image'>
                                    <Link to={`/${userDetail.username}`} >
                                        <img src={userDetail.image || '/images/user.png' } alt='Profile image' />
                                    </Link>
                                </span>
                                <p>
                                    <span className='notification-wrap'><Link to={`/${userDetail.username}`}>{userDetail.first} {userDetail.last} </Link> &nbsp;  <Link to={url}> {messagePost}  {message}</Link>
                                    </span><span>{moment(item.createdAt).fromNow()}</span>
                                </p>
                                <TiTimes onClick = {()=>handleRemoveNotication(item)}/>
                            </li>
                        {/* </Link> */}
                    </ul>
                );
            });
        }else{
            return []
        }
    };
    
    const renderNotification = () =>{
        if (!notification || notification.length === 0) {
            return null;
        }
        const nonCommentLikes = notification.filter((item) => item.type !== "COMMENTLIKE");
        const commentLikes = consolidateCommentLikes(notification.filter((item) => item.type === "COMMENTLIKE"));
        const renderedNonCommentLikes = renderNonCommentLikesNotifications(nonCommentLikes);
        const renderedCommentLikes = renderCommentLikesNotifications(commentLikes);
        const combinedNotifications = [ ...renderedNonCommentLikes , ...renderedCommentLikes].slice(0, 5);
        return (
            <>
                {combinedNotifications}
            </>
        );

    }

    const showNotificationCount = () =>{
        const check = ['undefined' , 0 , '0' , '' , null , 'null']
        if(notification && notification.length > 0 ){
            if(!check.includes(notificationCount)){
                return (
                    <span className='badge'>{notificationCount}</span>
                )
            }
        }
           
    }

    const showViewAll = () =>{
        if(notification && notification.length > 5){
            return <li><Link to='/notifications'>View All</Link></li>
        }
    }

    //**********************   autoSuggestion function  */////////////////////////////////////////////////////

    useEffect(()=>{
        if(GlobalSearchData && GlobalSearchData.searchResultList){
            if(GlobalSearchData.searchResultList.success){
                setSuggestion([]);
                handleSuggestions(GlobalSearchData.searchResultList.searchedData)
            }
        }
    },[GlobalSearchData])

    const handleSuggestions = (data) => {
        const combinedSuggestions = [];
        if (data.postResult && data.postResult.length > 0) {
          combinedSuggestions.push({
            category: 'Posts',
            suggestions: data.postResult.map((item, ind) => item.name)
          });
        }
        if (data.userResult && data.userResult.length > 0) {
          combinedSuggestions.push({
            category: 'Users',
            suggestions: data.userResult.map((item, ind) => item.first)
          });
        }
        if (combinedSuggestions.length > 0) {
          const totalSuggestions = combinedSuggestions.reduce((acc, item) => acc.concat(item.suggestions), []);
          const uniqueSuggestions = [...new Set(totalSuggestions)]; // Remove duplicates
          setSuggestion(uniqueSuggestions);
        }
    }
      
    const onSuggestionsFetchRequested = async ({ value }) => {
        const data = {searchAny:value , perPage: 5 , page : 1}
        dispatch(getSearchData(data))
        setSuggestion([])
    }
    
    const onSuggestionsClearRequested = () => {
       setSuggestion([]);
       setSearch('')
    };


    const getSuggestionValue = (search) => {
       
        if (search) {
            const data = {searchAny: search , perPage: 10 , page : 1}
            handleSearchData(data)
        }
    }

    const renderSuggestion = (suggestion, { query }) =>{
        if(suggestion && suggestion.length > 0)
        return (
            <div>
                <div><span>{suggestion}</span></div>
            </div>
        )
    }


    const handleEnterKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (search.trim() !== '') {
                const data = {searchAny: search , perPage: 10 , page : 1 }
                handleSearchData(data)
                setSuggestion([])
            }
        }
    };

    const inputProps = {
        placeholder: `Search`,
        value: search,
        onChange:(e, { newValue })=>{
            if (typeof newValue === "string") {
                setSearch(newValue); // Ensure newValue is a string before trimming
            } else {
                setSearch(""); // Set search to empty string if newValue is undefined or not a string
            }
        },
        onKeyPress: handleEnterKeyPress,
    };

    const showUnseenCount = () =>{
        const check = ['' , null , undefined , 'undefined' , [] , 0]
        if(messengerResult && messengerResult.unseen_count){
            if(!check.includes(messengerResult.unseen_count)){
                return (
                    <span className='badge'>{messengerResult.unseen_count}</span>

                )
            }
        }
    }

    const handleCartLength = () =>{
        if(cartItems && cartItems.length > 0){
            const filterItem = cartItems.filter((item , ind)=>{
                if(item.marketplaceProduct !== null){
                    return item
                }
            })
            if(filterItem && filterItem.length > 0){
                return (<span className='badge'>{filterItem.length}</span>)
            }
        }
    }

    return (
        <div id='page--Header' className={showHeader.isActive ? 'collapse--header' : ''}>
            <button id='header--collapse' className={showHeader.isActive ? 'header--Toggle' : ''} onClick={handleClick}><span>&lsaquo;</span></button>
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <div className='header--elements new-header--elements'>
                            <Link to = '/'>
                                <Navbar.Brand >
                                    <span className='collapse--icon'>G</span>
                                    <img src='/images/logo.png' alt='The Galaxy' />
                                </Navbar.Brand>
                            </Link>
                           
                            <ListGroup horizontal className='ms-auto'>
                            { loader ? null :
                            
                            (auth && auth.loggedIn)
                            ?   
                                    <>

                                        <Autosuggest 
                                            suggestions={suggestion}
                                            onSuggestionsFetchRequested={
                                                onSuggestionsFetchRequested
                                            }
                                            onSuggestionsClearRequested={
                                                onSuggestionsClearRequested
                                            }
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={inputProps}
                                        />
                                        <ListGroup.Item>
                                            <Link to='/create'>
                                                <AiOutlinePlusCircle />
                                            </Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Link to='/messenger'>
                                                <BiCommentDots />
                                                {showUnseenCount()}
                                            </Link> 
                                        </ListGroup.Item>
                                        <ListGroup.Item action>
                                            <Link to='/cart'>
                                                { handleCartLength()
                                                    // <span className='badge'>{cartItems.length}</span>
                                                }
                                                <TiShoppingCart />
                                            </Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Dropdown className='noti--dropdown'>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    <BiBell />
                                                    {showNotificationCount()}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <h4>Notifications</h4>
                                                    {renderNotification()}
                                                    {showViewAll()}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Dropdown>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    <img src={userProfile.image || '/images/user.png' } alt='Profile image' />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Link to={`/${userProfile.username}`} className='dropdown-item'>Profile</Link>
                                                    <Link to="/drafts" className='dropdown-item'>Drafts</Link>
                                                    <NavDropdown title="Shop" id="basic-nav-dropdown">
                                                        <Link className='dropdown-item' to="/my-shop">
                                                            My Shop
                                                        </Link>
                                                        <Link className='dropdown-item' to="/orders">
                                                            Orders
                                                        </Link>
                                                        <Link className='dropdown-item' to="/purchases">
                                                            Purchases
                                                        </Link>
                                                    </NavDropdown>
                                                    <Link className='dropdown-item' to="/meetings"> My Meetings</Link>
                                                    <Link to="/profile-settings" className='dropdown-item'>Settings</Link>
                                                    <Dropdown.Item onClick = {()=>logout()}>Logout</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </ListGroup.Item>
                                    </>
                                
                            :  ( 
                                    <>

                                        <ListGroup.Item action>
                                            <Link to='/login'>
                                                Login
                                            </Link>
                                        </ListGroup.Item>
                                    </>
                                )
                            }
                            </ListGroup>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default PageHeader;